<template>
  <div @click="isShow = false">
    <!-- 头部 -->
    <div class="header flex ai-c jc-c">
      <div class="headermain flex fd-c jc-c">
        <div class="success flex ai-c">
          <img class="imgtime"
               src="/img/refule/time.png"
               alt="">
          <div class="txt">
            退款成功
          </div>
          <div class="ts flex ai-c" @click.stop="isShow = !isShow">
            未收到退款
            <img class="mark" src="/img/question-mark.png" alt="">
            <img v-show="isShow" class="ts-img" src="/img/ts-img.png" alt="">
          </div>
        </div>
        <div class="time">
          {{details.createTime}}
        </div>
      </div>
    </div>
    <!-- 退款金额 -->
    <div class="myt">
      <div class="myt-t flex ai-c jc-sb">
        <div class="flex ai-c ">
          <img class="mey"
               src="/img/refule/money.png"
               alt="">
          <div class="qn">退款总金额</div>
        </div>
        <div class="qn">
          ¥{{details.returnAmount}}
        </div>
      </div>
      <div class="xia"></div>
      <div class="myt-t flex ai-c jc-sb">
        <div class="flex ai-c ">
          <div class="mey flex ai-c jc-c">
            <div class="yuan"></div>
          </div>
          <div class="qnk">退回支付账号</div>
        </div>
        <div class="qnk">
          ¥{{details.returnAmount}}
        </div>
      </div>
    </div>
    <!-- 身体 -->
    <v-refuleinfo :details="details"></v-refuleinfo>
  </div>
</template>

<script>
// 退款信息
import refuleinfo from './refuleinfo.vue'
export default {
  props: ["details"],
  components: {
    'v-refuleinfo': refuleinfo,
  },
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style scoped>
.myt {
  height: 2.08rem;
  box-sizing: border-box;
  padding: 0 0.32rem;
  background-color: #fff;
  margin-bottom: 0.3rem;
}
.myt .tkzje {
}
.myt .myt-t {
  height: 1.04rem;
}
.yuan {
  width: 0.06rem;
  height: 0.06rem;
  background: #bdbdbd;
  border-radius: 50%;
}
.myt .mey {
  width: 0.34rem;
  height: 0.32rem;
  margin-right: 0.2rem;
  color: #bdbdbd;
  text-align: center;
  line-height: 0.62rem;
}
.myt .qnk {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #b4b4b4;
}
.xia {
  width: 6.98rem;
  height: 0.01rem;
  background: #e7e7e7;
  margin: 0 auto;
}
.myt .qn {
  font-size: 0.32rem;
  font-family: PingFang;
  font-weight: bold;
  color: #f35e3a;
}
.ts{
  position: relative;
  font-size: .20rem;
  font-family: PingFang;
  font-weight: 500;
  color: #AAAAAA;
  margin-top: -.3rem;
  margin-left: .15rem;
}
.ts .mark{
  width: .3rem;
  height: .3rem;
  margin-left: .1rem;
}
.ts-img {
  position: absolute;
  left: .8rem;
  top: .4rem;
  width: 3.73rem;
  height: 1.06rem;
}
</style>
