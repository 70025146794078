<template>
  <!-- 身体 -->
  <div class="main">
    <div class="tkxi">
      退款信息
    </div>
    <div class="flex jc-sb">
      <div class="lf">
        <img :src="details.goodsPic" alt="">
      </div>
      <div class="ri">
        <div class="flex ai-c jc-sb">
          <div class="sz">{{details.goodsName}}</div>
          <div class="my">￥{{details.goodsPrice}}</div>
        </div>
        <div class="flex ai-c jc-sb">
          <div class="sz sza">{{details.goodsAttr}}</div>
          <div class="mya">x{{details.goodsCount}}</div>
        </div>
      </div>
    </div>
    <div class="mb15 flex ai-c">
      <div class="yi">退款原因</div>
      <div class="xh">{{details.reason}}</div>
    </div>
    <div class="mb15 flex ai-c">
      <div class="yi">退款金额</div>
      <div class="xh">¥{{details.returnAmount}}</div>
    </div>
    <div class="mb15 flex ai-c">
      <div class="yi">申请件数</div>
      <div class="xh">{{details.goodsCount}}</div>
    </div>
    <div class="mb15 flex ai-c">
      <div class="yi">申请时间</div>
      <div class="xh">{{details.createTime}}</div>
    </div>
    <div class="mb15 flex ai-c ">
      <div class="yi">退款编号</div>
      <div class="xh flex jc-sb ai-c">
        <div>{{details.applySn}}</div>
        <div class="copy copyTxt"
             @click="copy"
             :data-clipboard-text="details.applySn">复制</div>
      </div>
    </div>
    <div class="mbtb flex">
      <div class="btn"
           @click="$router.push('/my/service')">联系客服</div>
      <!-- <div v-if="details.status==4||details.status==5" @click="$router.push({path:'/my/orderListSMCS/refund',query: $route.query})" class="btn">申请售后</div> -->
      <div v-if="details.status!=4&&details.status!=5&&details.status!=2&&details.status!=3" class="btn sq" @click="getReturnApplyCancel()">撤销申请</div>
    </div>
  </div>
</template>

<script>
import { getReturnApplyCancel } from '../../../../../../api'
export default {
  props: ['details'],
  created() {
    
  },
  methods: {
    copy() {
      var clipboard = new this.Clipboard(".copyTxt");
      clipboard.on("success", (e) => {
        console.log(e)
        // 释放内存
        this.$toast.success("复制成功!");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e)
        // 不支持复制
        this.$dialog
          .alert({
            message: "该浏览器不支持自动复制",
          })
          .then(() => {
            // on close
          });
        // 释放内存
        clipboard.destroy();
      });
    },
    getReturnApplyCancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '确认撤销申请吗？',
      })
        .then(() => {
          // on confirm
          this.$toast.loading({
            duration: 0,
            forbidClick: true
          })
          getReturnApplyCancel(this.$route.query.orderItemId).then(res=>{
            this.$toast.success('撤销成功')
            this.$router.go(-1)
            // this.$root.eventHub.$emit('send')
          }).catch(()=>{
            this.$toast.clear()
          })
        })
        .catch(() => {
          // on cancel
        })
      
    }
  }
}
</script>

<style scoped>
.main .btn {
  width: 1.7rem;
  height: 0.7rem;
  line-height: 0.7rem;
  text-align: center;
  background: #fdfeff;
  border: 0.02rem solid #61626a;
  border-radius: 0.35rem;
  margin-right: 0.27rem;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .wzjc {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}

.main .sq {
  color: #f35e3a;
  border: 0.02rem solid #f35e3a;
  margin-right: 0;
}
.main .copy {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(243, 94, 58, 1);
}
.main .xh {
  width: calc(100% - 1.76rem);
  text-align: left;
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .yi {
  width: 1.76rem;
  text-align: left;
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #c9c9c9;
}
.main .my {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .sz {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .sza {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
  background: #f4f4f4;
}
.main .mya {
  font-size: 0.24rem;
  color: #aaaaaa;
}
.main .ri {
  width: calc(100% - 1.76rem);
}
.main .lf {
  width: 1.54rem;
  height: 1.54rem;
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  border-radius: 0.01rem;
  margin-bottom: 0.5rem;
}
.main .lf img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main {
  background-color: #fff;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.2rem;
  margin-bottom: 0.3rem;
}
</style>
