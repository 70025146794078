<template>
  <div>
    <!-- 头部 -->
    <div class="header flex ai-c jc-c">
      <div class="headermain flex fd-c jc-c">
        <div class="success flex ai-c">
          <img class="imgtime"
               src="/img/refule/time.png"
               alt="">
          <div class="txt">
            退款申请失败
          </div>
        </div>
        <div class="time">
          {{details.handleTime}}
        </div>
      </div>
    </div>
    <!-- 退款金额 -->
    <div class="myt">
      <div class="sjtksq">
        商家已拒绝你的退款申请
      </div>
      <div class="wz">
        {{details.handleNote}}
      </div>
    </div>
    <!-- 身体 -->
    <v-refuleinfo :details="details"></v-refuleinfo>
  </div>
</template>

<script>
// 退款信息
import refuleinfo from './refuleinfo.vue'
export default {
  props: ['details'],
  components: {
    'v-refuleinfo': refuleinfo,
  }
}
</script>

<style scoped>
.myt {
  box-sizing: border-box;
  padding: 0.22rem;
  background-color: #fff;
  margin-bottom: 0.3rem;
  padding-bottom: 0.5rem;
}
.sjtksq {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.wz {
  margin-left: 0.3rem;
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626A;
}
</style>
