<template>
  <div class="wrapper" v-if="JSON.stringify(details)!='{}'">
    <!-- 寄回货 -->
    <v-sendGoods v-if="details.status==1||details.status==2" :details="details"></v-sendGoods>
    <!-- 退款成功 -->
    <v-refundSuccess v-if="details.status==3" :details="details"></v-refundSuccess>
    <!-- 退款申请失败 -->
    <v-refuleFailed v-if="details.status==4" :details="details"></v-refuleFailed>
    <!-- 退款申请中 -->
    <v-refuleProgress v-if="details.status==0" :details="details"></v-refuleProgress>

    <!-- 退款申请撤销 -->
    <v-refuleRevoke v-if="details.status==5" :details="details"></v-refuleRevoke>

    <!-- 精选好物 -->
    <div class="footer" v-if="goodsList.length>0">
      <div class="jxhw">
        是否需要重新购买？为您精选好物
      </div>
      <ul class="">
        <li class="itemli"
            v-for="(it, index) in goodsList"
             @click="$router.push({path:'/home/details',query: {id: it.goodsId}})"
            :key="index">
          <div class="liimg flex ai-c jc-c">
            <img :src="it.pic"
                 alt="">
          </div>
          <div class="zj">
            {{it.name}}
          </div>
          <div class="zr ellipsis">
            {{it.keywords}}
          </div>
          <div class="zz">
            ¥{{it.priceLining}}
          </div>
          <div class="zx flex jc-sb ai-c">
            <div>
              <span style="font-size: 0.21rem;">¥</span>{{it.price}}
            </div>
            <img class="zimg"
                 src="/img/cart.png"
                 alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getReturnApply, goodsTag } from '../../../../../api'
import sendGoods from './components/sendGoods.vue'
import refundSuccess from './components/refundSuccess.vue'
import refuleFailed from './components/refuleFailed.vue'
import refuleProgress from './components/refuleProgress.vue'
import refuleRevoke from './components/refuleRevoke.vue'
export default {
  data() {
    return {
      details: {},
      goodsList: []
    }
  },
  components: {
    'v-sendGoods': sendGoods,
    'v-refundSuccess': refundSuccess,
    'v-refuleFailed': refuleFailed,
    'v-refuleProgress': refuleProgress,
    'v-refuleRevoke': refuleRevoke,
  },
  methods: {
    getReturnApply() {
      getReturnApply({
        orderItemId:this.$route.query.orderItemId,
        id:this.$route.query.orderId?this.$route.query.orderId:''
      }).then(res=>{
        this.details = res.data.data
        
      })
    },
    goodsTag() {
      goodsTag({
        goodsTagId: 2,
        current: 1,
        size: 6,
      }).then(res=>{
        var list = res.data.data.records
        for (var item of list) {
          item.pic = item.pic?item.pic.split(',')[0]:[]
          this.goodsList.push(item)
        }
      })
    },
  },
  created() {
    this.getReturnApply()
    this.goodsTag()
  },
  mounted () {
    // this.$root.eventHub.$on('send', ()=>{
    //   this.getReturnApply()
    // })
  }
}
</script>

<style>
.wd {
  width: calc(100% - 0.95rem);
}
.xian {
  width: 6.98rem;
  height: 1px;
  background: #e7e7e7;
  margin: 0 auto;
  margin-bottom: 0.7rem;
}
.mbtb {
  margin: 0.7rem 0;
  justify-content: flex-end;
}
.bsbb {
  box-sizing: border-box;
}
.wrapper {
  background-color: RGBA(244, 244, 244, 1);
}
.main .tkxi {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-bottom: 0.4rem;
}
.header {
  height: 1.98rem;
  background: linear-gradient(90deg, #f3803a 0%, #f35e3a 100%);
  box-sizing: border-box;
  margin-bottom: 0.3rem;
}
.header .headermain {
  width: 6.83rem;
  height: 1.44rem;
  background-color: #fff;
  border-radius: 0.1rem;
  padding-left: 0.24rem;
  box-sizing: border-box;
}
.header .imgtime {
  width: 0.38rem;
  height: 0.38rem;
  margin-right: 0.05rem;
}
.header .time {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
}
.header .txt {
  font-size: 0.36rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.footer {
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  background-color: #fff;
  overflow: hidden;
}
.footer .jxhw {
  font-size: 0.32rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-bottom: 0.3rem;
}
.footer .itemli {
  width: 1.91rem;
  overflow: hidden;
  font-family: PingFang;
  font-weight: 500;
  margin-right: 0.2rem;
  display: inline-block;
}
.footer .liimg {
  width: 1.91rem;
  height: 1.91rem;
  border: 1px solid #f5f5f5;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.footer .liimg img {
  width: 100%;
}
.footer .zj {
  font-size: 0.23rem;
  color: #1a1a1a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.footer .zr {
  max-width: 100%;
  font-size: 0.18rem;
  color: #f36e3f;
  background: #ffeee1;
  border-radius: 0.2rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  overflow: hidden;
}
.footer .zz {
  font-size: 0.18rem;
  color: #999999;
  text-decoration: line-through;
  margin-top: 0.05rem;
}
.footer .zx {
  font-size: 0.23rem;
  color: #f22e1a;
}
.footer .zimg {
  width: 0.36rem;
  height: 0.36rem;
}
.footer ul {
  width: auto;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
