<template>
  <div>
    <!-- 头部 -->
    <div class="header flex ai-c jc-c">
      <div class="headermain flex fd-c jc-c">
        <div class="success flex ai-c">
          <img class="imgtime"
               src="/img/refule/time.png"
               alt="">
          <div class="txt">
            退款申请成功，请尽早退货给商家
          </div>
        </div>
        <div class="time">
          {{details.createTime}}
        </div>
      </div>
    </div>
    <!-- 身体 -->
    <div class="main">
      
      <div class="flex">
        <div class="flex jc-c ai-c">
          <img class="zrt"
               src="/img/refule/zrt.png"
               alt="">
        </div>
        <div>
          <div class="flex ai-c">
            <span class="sx">收货人：{{details.receiveMan}}</span>
            <span class="phone">{{details.receivePhone}}</span>
          </div>
          <div class="flex ai-c copyTxtaddress"
               @click="copy"
               :data-clipboard-text="details.receiveAddress">
            <span class="sx sxa">{{details.receiveAddress}}<span class="phone phonea ">复制</span></span>
          </div>
        </div>
      </div>
      <div class="flex"
           style="margin-top: .7rem;">
        <div>
          <img class="zwl"
               src="/img/refule/zwl.png"
               alt="">
        </div>
        <div class="wd">
          <div class="wzjc">我已寄出</div>
          <div class="flex jc-sb ai-c ">
            <div class="sx sxa">点击填写物流单号</div>
            <div class="btn btndh" @click="logisticClick">填写单号</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 身体 -->
    <!-- <div class="xian"></div> -->
    <v-refuleinfo :details="details"></v-refuleinfo>
      
  </div>
</template>

<script>
// 退款信息
import refuleinfo from './refuleinfo.vue'
export default {
  props: ["details"],
  components: {
    'v-refuleinfo': refuleinfo,
  },
  methods: {
    logisticClick(){
      localStorage.setItem('logisticInfo', JSON.stringify(this.details))
      this.$router.push({path:'/my/orderListSMCS/refund/inputlogistic',query: {orderItemId:this.$route.query.orderItemId}})
    },
    copy() {
      var clipboard = new this.Clipboard(".copyTxtaddress");
      clipboard.on("success", (e) => {
        console.log(e)
        // 释放内存
        this.$toast.success("复制成功!");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e)
        // 不支持复制
        this.$dialog
          .alert({
            message: "该浏览器不支持自动复制",
          })
          .then(() => {
            // on close
          });
        // 释放内存
        clipboard.destroy();
      });
    },
  }
}
</script>

<style scoped>
.main .zwl {
  width: 0.35rem;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}
.main .phone {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: bold;
  color: #61626a;
}
.main .zrt {
  width: 0.35rem;
  height: 0.3rem;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}
.main .sx {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-right: 0.3rem;
}
.main .sxa {
  font-size: 0.28rem;
  color: #61626a;
  margin-right: 0;
}
.main .phonea {
  font-weight: 500;
  font-size: 0.24rem;
  color: #3aabf3;
  margin-left: 0.2rem;
}
.main .sza {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
  background: #f4f4f4;
}
.main .btn {
  width: 1.7rem;
  height: 0.7rem;
  line-height: 0.7rem;
  text-align: center;
  background: #fdfeff;
  border: 0.02rem solid #61626a;
  border-radius: 0.35rem;
  margin-right: 0.27rem;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
}
.main .btndh {
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.24rem;
  margin-right: 0;
}
.main {
  background-color: #fff;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.2rem;
  margin-bottom: 0.3rem;
}
</style>
